import { useEffect, useState } from 'react';

const desktopMediaQuery = window.matchMedia('(min-width: 992px)');

export default function useOnDesktop() {
  const [onDesktop, setOnDesktop] = useState(true);

  useEffect(() => {
    const onDesktopMediaQueryListener = () => {
      setOnDesktop(desktopMediaQuery.matches);
    };

    desktopMediaQuery.addEventListener('change', onDesktopMediaQueryListener);

    return () => {
      desktopMediaQuery.removeEventListener('change', onDesktopMediaQueryListener);
    };
  }, [setOnDesktop]);

  return onDesktop;
}
