$(() => {
  const checkAllElements = function checkAllElements() {
    const { select } = this.dataset;
    const options = document.querySelectorAll(`${select} > option`);

    if (this.checked) {
      options.forEach((option) => option.setAttribute('selected', true));
    } else {
      options.forEach((option) => option.removeAttribute('selected'));
    }

    // call to select2 (jquery library) and trigger changes
    $(select).select2().trigger('change');
  };

  const element = document.querySelector('#checkAll');
  !!element && element.addEventListener('change', checkAllElements);
});
