import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function DataTableColumn(
  {
    field,
    value,
    item,
    truncated = false,
    onTruncatedColumnClick
  }
) {
  const valueFormatted = useMemo(() => {
    switch (field.type) {
      case 'moment':
        return moment(value).format(field.typeTransformer);

      default:
        return value;
    }
  }, [value, field.type, field.typeTransformer]);

  return (
    <div
      data-header={field.name}
      className={`col-lg-${field.distribution}`}
    >
      {(field.type !== 'link' && field.isTruncate) && (
        <button
          type="button"
          className={`btn btn-link grid-row-field-content ${(field.isTruncate && truncated) ? 'grid-row-field-content-truncate' : ''}`}
          onClick={() => {
            if (field.isTruncate && onTruncatedColumnClick) {
              onTruncatedColumnClick();
            }
          }}
        >
          {valueFormatted}
        </button>
      )}
      {(field.type !== 'link' && !field.isTruncate) && (
        <div className="grid-row-field-content">
          {valueFormatted}
        </div>
      )}
      {field.type === 'link' && (
        <a href={item[field.typeTransformer]} className={`grid-row-field-content ${field.isTruncate ? 'grid-row-field-content-truncate' : ''}`}>
          {valueFormatted}
        </a>
      )}
    </div>
  );
}

DataTableColumn.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distribution: PropTypes.number.isRequired,
    isTruncate: PropTypes.bool.isRequired,
    isSortable: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    type: PropTypes.string,
    typeTransformer: PropTypes.string
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    viewUrl: PropTypes.string
  }).isRequired,
  truncated: PropTypes.bool,
  onTruncatedColumnClick: PropTypes.func
};
