export default {
  items: [],
  count: 0,
  limit: 5,
  offset: 0,
  sort: {},
  selected: {},
  flags: {
    isDisabledPagination: false,
    isSelectable: false
  }
};
