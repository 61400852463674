import Cookies from 'js-cookie';

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATH: 'PATH',
  DELETE: 'DELETE'
};

export default async function request(
  url,
  method = Method.GET,
  query = {},
  content
) {
  const body = content ? JSON.stringify(content) : null;
  const csrfToken = Cookies.get('csrftoken');

  const params = Object.entries(query).reduce((newParams, [key, value]) => {
    newParams.append(key, value);
    return newParams;
  }, new URLSearchParams());

  return fetch(
    new Request(
      `/api/v1/${url}${params}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      }
    ),
    {
      method,
      body,
      //mode: 'same-origin'
      mode: 'no-cors'
    }
  )
    .then(async (response) => {
      if (!response.ok) {
        const json = await response.json();
        const error = new Error(json.error);
        error.raw = json;
        throw error;
      }
      return response.json();
    });
}
