import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Overlay from '../Overlay';
import ErrorModal from '../ErrorModal';
import ManifestHeader from './components/ManifestHeader';
import ManifestActions from './components/ManifestActions';
import useGuides from './hooks/useGuides';
import ManifestGuides from './components/ManifestGuides';

export default function Manifest({ manifestId, concludeClassificationUrl }) {
  const actionsRef = useRef(null);
  const listRef = useRef(null);

  const {
    data: {
      guides,
      scans,
      error
    },
    actions: {
      onScan,
      clearError
    }
  } = useGuides(
    manifestId,
    () => {
      actionsRef.current.focusScanner();
      listRef.current.scrollToPosition(0);
    },
    () => {
      actionsRef.current.blurScanner();
    }
  );

  const onCloseError = () => {
    clearError();
    actionsRef.current.focusScanner();
  };

  return (
    <div className="manifest h-100 d-flex flex-column">
      <ManifestHeader
        totalGuides={guides.length}
        totalScans={scans}
        concludeClassificationUrl={concludeClassificationUrl}
      />
      <ManifestActions ref={actionsRef} onScan={onScan} />
      <ManifestGuides guides={guides} listRef={listRef} />
      <Overlay show={!!error}>
        <ErrorModal
          error={error}
          onClose={onCloseError}
        />
      </Overlay>
    </div>
  );
}

Manifest.propTypes = {
  manifestId: PropTypes.number.isRequired,
  concludeClassificationUrl: PropTypes.string.isRequired
};
