import React, { useMemo, useCallback } from 'react';

import { useListContext } from '../context/ListContext';

import '../styles/data-table-pagination.scss';

export default function DataTablePagination() {
  const {
    limit,
    offset,
    count,

    setOffset
  } = useListContext();

  const pages = useMemo(() => Math.trunc(count / limit) + 1, [count, limit]);
  const current = useMemo(() => offset / limit, [offset, limit]);

  const goPrev = useCallback(() => {
    if (current === 0) {
      return;
    }

    setOffset(offset - limit);
  }, [current, limit, offset, setOffset]);

  const goTo = useCallback((page) => {
    if (page < 0 && page > pages) {
      return;
    }

    setOffset(limit * page);
  }, [limit, pages, setOffset]);

  const goNext = useCallback(() => {
    if (current === pages) {
      return;
    }

    setOffset(offset + limit);
  }, [current, limit, offset, pages, setOffset]);

  if (pages === 0) {
    return null;
  }

  return (
    <nav className="data-table-pagination" aria-label={gettext('table pagination')}>
      <ul className="pagination">
        <li className={`page-item ${current === 0 ? 'disabled' : ''}`}>
          <button
            className="page-link"
            type="button"
            aria-label={gettext('previous')}
            onClick={() => goPrev()}
          >
            <i className="fas fa-angle-double-left" />
          </button>
        </li>
        {pages <= 4 ? (
          <>
            {Array.from(Array(pages)).map((_, index) => {
              const pageIndex = index + 1;
              return (
                <li
                  key={`page-item-${pageIndex}`}
                  className={`page-item ${current === index ? 'active' : ''}`}
                >
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(index)}
                  >
                    {pageIndex}
                  </button>
                </li>
              );
            })}
          </>
        ) : (
          <>
            {current < 2 && (
              <>
                {Array.from(Array(3)).map((_, index) => {
                  const pageIndex = index + 1;
                  return (
                    <li
                      key={`page-item-${pageIndex}`}
                      className={`page-item ${current === index ? 'active' : ''}`}
                    >
                      <button
                        className="page-link"
                        type="button"
                        onClick={() => goTo(index)}
                      >
                        {pageIndex}
                      </button>
                    </li>
                  );
                })}
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
                <li className={`page-item ${pages === current ? 'active' : ''}`}>
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(pages - 1)}
                  >
                    {pages}
                  </button>
                </li>
              </>
            )}
            {(current >= 2 && current < pages - 2) && (
              <>
                <li className={`page-item ${current === 0 ? 'active' : ''}`}>
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(0)}
                  >
                    {1}
                  </button>
                </li>
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(current - 1)}
                  >
                    {current}
                  </button>
                </li>
                <li className="page-item active">
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(current)}
                  >
                    {current + 1}
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(current + 1)}
                  >
                    {current + 2}
                  </button>
                </li>
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
                <li className={`page-item ${pages === current ? 'active' : ''}`}>
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(pages - 1)}
                  >
                    {pages}
                  </button>
                </li>
              </>
            )}
            {current >= pages - 2 && (
              <>
                <li className={`page-item ${current === 0 ? 'active' : ''}`}>
                  <button
                    className="page-link"
                    type="button"
                    onClick={() => goTo(0)}
                  >
                    {1}
                  </button>
                </li>
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
                {Array.from(Array(3)).map((_, baseIndex) => {
                  const index = pages - baseIndex - 1;
                  const pageIndex = index + 1;
                  return (
                    <li
                      key={`page-item-${pageIndex}`}
                      className={`page-item ${current === index ? 'active' : ''}`}
                    >
                      <button
                        className="page-link"
                        type="button"
                        onClick={() => goTo(index)}
                      >
                        {pageIndex}
                      </button>
                    </li>
                  );
                }).reverse()}
              </>
            )}
          </>
        )}
        <li className={`page-item ${pages - 1 === current ? 'disabled' : ''}`}>
          <button
            className="page-link"
            type="button"
            aria-label={gettext('next')}
            onClick={() => goNext()}
          >
            <i className="fas fa-angle-double-right" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
