import React from 'react';
import PropTypes from 'prop-types';

import './styles/data-table.scss';
import { ListStateProvider } from './context/ListContext';
import DataTable from './components/DataTable';
import { DesignStateProvider } from './context/DesignContext';

export default function DataTableContainer(
  {
    initialUrl,
    updateUrl,
    columns,
    filters = [],
    actions,
    flags = {}
  }
) {
  return (
    <DesignStateProvider
      columns={columns}
      actions={actions}
      flags={flags}
    >
      <ListStateProvider
        initialUrl={initialUrl}
        updateUrl={updateUrl}
        filters={filters}
        isDisabledPagination={flags.isDisabledPagination}
      >
        <DataTable />
      </ListStateProvider>
    </DesignStateProvider>
  );
}

DataTableContainer.propTypes = {
  initialUrl: PropTypes.string.isRequired,
  updateUrl: PropTypes.string.isRequired,
  columns: PropTypes
    .arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      distribution: PropTypes.number.isRequired,
      isTruncate: PropTypes.bool.isRequired,
      isSortable: PropTypes.bool.isRequired
    }))
    .isRequired,
  actions: PropTypes.shape({
    view: PropTypes.string,
    edit: PropTypes.string,
    delete: PropTypes.string
  }),
  filters: PropTypes
    .arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })),
  flags: PropTypes.shape({
    isDisabledPagination: PropTypes.bool,
    isSelectable: PropTypes.bool
  })
};
