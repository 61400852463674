$(() => {
  const searchGuide = function searchGuide(e) {
    e.preventDefault();
    const guideValues = this.querySelector('#guidesInput').value;
    const guideInput = this.querySelector('#guideInput');
    const guideValue = guideInput.value.trim();
    const errorAlert = document.querySelector('#errorAlert');
    const guides = guideValues.split(',').map((x) => x.trim().toUpperCase());

    errorAlert.classList.add('d-none');

    if (guides.indexOf(guideValue.toUpperCase()) === -1) {
      errorAlert.querySelector('span#guideID').textContent = guideValue;
      errorAlert.classList.remove('d-none');
      guideInput.value = '';
      return;
    }

    // TODO: play a audio when guide is found

    const button = document.querySelector('#launchModal');
    const span = document.querySelector('span#guideFound');

    span.textContent = guideValue;
    button.click();
  };

  const element = document.querySelector('#guideSearchForm');
  !!element && element.addEventListener('submit', searchGuide);
});
