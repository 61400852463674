import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDesignContext } from '../context/DesignContext';
import { useListContext } from '../context/ListContext';

import DataTableColumn from './DataTableColumn';
import DataTableEditableColumn from './DataTableEditableColumn';

export default function DataTableRow({ item }) {
  const { columns, actions, flags } = useDesignContext();
  const {
    changeItem,
    selected,
    toggleSelectedItem
  } = useListContext();
  const [truncated, setTruncated] = useState(true);
  const [status, setStatus] = useState(null);

  const onChangeCompleted = (value, key) => {
    setStatus('waiting');

    changeItem(item.id, { [key]: value })
      .then(() => setStatus('success'))
      .catch(() => setStatus('failed'));
  };

  const onSelectItem = () => {
    toggleSelectedItem(item.id);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (status === 'success') {
      const timer = setTimeout(() => {
        setStatus(null);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [status]);

  const backgroundClass = status ? `data-table-item-${status}` : '';

  return (
    <div
      className={`row grid-row data-table-item ${backgroundClass}`}
    >
      {flags.isSelectable && (
        <div className="grid-row-select">
          <input
            id={`check-${item.id}`}
            type="checkbox"
            className="form-control"
            checked={selected[item.id] || false}
            onChange={onSelectItem}
          />
        </div>
      )}
      {actions && (
        <div className="grid-row-actions">
          {actions.view && (
            <a href={item[actions.view]}>
              <i className="fas fa-eye" />
            </a>
          )}
          {actions.edit && (
            <a href={item[actions.edit]}>
              <i className="fas fa-edit" />
            </a>
          )}
          {actions.delete && (
            <a href={item[actions.delete]}>
              <i className="fas fa-trash" />
            </a>
          )}
        </div>
      )}
      <div className="grid-row-content">
        {columns.map((column) => (
          !column.isEditable ? (
            <DataTableColumn
              key={`column-field-${column.key}`}
              field={column}
              value={item[column.key]}
              truncated={truncated}
              onTruncatedColumnClick={() => setTruncated(!truncated)}
              item={item}
            />
          ) : (
            <DataTableEditableColumn
              key={`column-field-${column.key}`}
              field={column}
              initialValue={item[column.key]}
              onChangeCompleted={onChangeCompleted}
            />
          )
        ))}
      </div>
    </div>
  );
}

DataTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};
