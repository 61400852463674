import React from 'react';
import PropTypes from 'prop-types';

import '../styles/manifest-total.scss';

export default function ManifestTotal({ title, total }) {
  return (
    <div className="manifest-total">
      <div>{title}</div>
      <div className="total">
        {total}
      </div>
    </div>
  );
}

ManifestTotal.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired
};
