import React, { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

const initialState = { fields: [] };

const DesignContext = createContext(initialState);

export function DesignStateProvider(
  {
    children,
    columns,
    actions,
    flags = {}
  }
) {
  const value = useMemo(() => ({
    columns,
    actions,
    flags
  }), [columns, actions, flags]);

  return (
    <DesignContext.Provider
      value={value}
    >
      {children}
    </DesignContext.Provider>
  );
}

DesignStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes
    .arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      distribution: PropTypes.number.isRequired,
      isTruncate: PropTypes.bool.isRequired,
      isSortable: PropTypes.bool.isRequired,
      isEditable: PropTypes.bool.isRequired
    }))
    .isRequired,
  actions: PropTypes.shape({
    view: PropTypes.string,
    edit: PropTypes.string,
    delete: PropTypes.string
  }),
  flags: PropTypes.shape({
    isDisabledPagination: PropTypes.bool,
    isSelectable: PropTypes.bool
  })
};

export const useDesignContext = () => useContext(DesignContext);
export default DesignContext;
