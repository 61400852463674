import React from 'react';
import PropTypes from 'prop-types';

import { useListContext } from '../context/ListContext';

const sortIcon = {
  asc: 'fa-sort-up',
  desc: 'fa-sort-down'
};

export default function SortIcon({ fieldKey }) {
  const { sort, sortBy } = useListContext();
  const sortConfig = sort[fieldKey] || {};
  const sortClass = sortIcon[sortConfig.direction] || 'fa-sort';

  return (
    <button
      type="button"
      className="btn btn-link ml-1 d-flex flex-nowrap"
      onClick={() => sortBy(fieldKey)}
    >
      <i className={`fas ${sortClass} mr-1`} />
      <small>{sortConfig.position}</small>
    </button>
  );
}

SortIcon.propTypes = {
  fieldKey: PropTypes.string.isRequired
};
