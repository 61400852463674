import React, {
  createContext,
  useContext,
  useState
} from 'react';
import PropTypes from 'prop-types';

import initialState from './initialState';
import useUrl from './hooks/useUrl';
import useList from './hooks/useList';
import useSelection from './hooks/useSelection';

const ListContext = createContext(initialState);

export function ListStateProvider(
  {
    children,
    initialUrl,
    updateUrl,
    filters = [],
    isDisabledPagination = false
  }
) {
  const [limit, setLimit] = useState(initialState.limit);
  const [offset, setOffset] = useState(initialState.offset);

  const {
    url,
    sort,
    sortBy
  } = useUrl(initialUrl, limit, offset, filters, isDisabledPagination);
  const {
    items,
    count,
    changeStatus,
    changeItem,
    clearChangeStatus
  } = useList(url, updateUrl);
  const { selected, toggleSelectedItem, selectAllItems } = useSelection(items);

  return (
    <ListContext.Provider
      value={{
        items,
        count,
        limit,
        changeStatus,
        offset,
        sort,
        selected,

        setLimit,
        setOffset,
        sortBy,
        changeItem,
        clearChangeStatus,
        toggleSelectedItem,
        selectAllItems
      }}
    >
      {children}
    </ListContext.Provider>
  );
}

ListStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialUrl: PropTypes.string.isRequired,
  updateUrl: PropTypes.string.isRequired,
  filters: PropTypes
    .arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })),
  isDisabledPagination: PropTypes.bool
};

export const useListContext = () => useContext(ListContext);
export default ListContext;
