import React, { useMemo } from 'react';
import Select, { components } from 'react-select';

import '../styles/data-table-limit.scss';

import { useListContext } from '../context/ListContext';

const options = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' }
];

function DropdownIndicator(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <i className="fas fa-caret-down" />
    </components.DropdownIndicator>
  );
}

export default function DataTableLimit() {
  const {
    limit,
    count,
    setLimit,
    setOffset
  } = useListContext();

  const currentOption = useMemo(() => (
    options.find((option) => option.value === limit)
  ), [limit]);

  return (
    <div className="data-table-limit">
      {gettext('showing')}
      <Select
        classNamePrefix="limit-select"
        className="data-table-limit-select"
        defaultValue={currentOption}
        components={{ DropdownIndicator }}
        options={options}
        onChange={(option) => {
          setLimit(option.value);
          setOffset(0);
        }}
      />
      {`${gettext('of')} ${count} ${gettext('elements')}`}
    </div>
  );
}
