import React from 'react';
import PropTypes from 'prop-types';

import '../styles/manifest-guide.scss';

export const HEADER_GUIDE = gettext('guide');
export const HEADER_STATUS = gettext('status');
export const HEADER_CONTENT = gettext('content');
export const HEADER_WEIGHT = gettext('weight');

function ManifestGuide({ guide }) {
  const percent = (guide.scannedPackagesAmount * 100) / guide.packagesAmount;

  const classificationClass = `classification-${guide.potentialClassification.toLowerCase()}`;

  return (
    <div className="manifest-guide row">
      <div className="manifest-guide-back col-12">
        <div className="row grid-row">
          <div
            data-header={HEADER_GUIDE}
            className="manifest-guide-number col-lg-5"
          >
            {guide.originGuideNumber}
          </div>
          <div
            data-header={HEADER_STATUS}
            className="col-lg-1 justify-content-lg-center"
          >
            {guide.actualCustomsStatus}
          </div>
          <div data-header={HEADER_CONTENT} className="col-lg-5">
            <span className="manifest-guide-truncate">
              {guide.translatedDescription}
            </span>
          </div>
          <div
            data-header={HEADER_WEIGHT}
            className="col-lg-1 justify-content-lg-center"
          >
            {guide.weight}
          </div>
        </div>
      </div>
      <div
        className={`manifest-guide-front container col-12 ${
          percent === 0 ? 'p-0' : ''
        } ${classificationClass}`}
        style={{
          width: `${percent}%`
        }}
      >
        <div className="row grid-row">
          <div
            data-header={HEADER_GUIDE}
            className="manifest-guide-number col-lg-5"
          >
            {guide.originGuideNumber}
          </div>
          <div
            data-header={HEADER_STATUS}
            className="col-lg-1 justify-content-lg-center"
          >
            {guide.actualCustomsStatus}
          </div>
          <div data-header={HEADER_CONTENT} className="col-lg-5">
            <span className="manifest-guide-truncate">
              {guide.translatedDescription}
            </span>
          </div>
          <div
            data-header={HEADER_WEIGHT}
            className="col-lg-1 justify-content-lg-center"
          >
            {guide.weight}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ManifestGuide);

ManifestGuide.propTypes = {
  guide: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    originGuideNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    originalCustomsStatus: PropTypes.string.isRequired,
    packagesAmount: PropTypes.number.isRequired,
    scannedPackagesAmount: PropTypes.number.isRequired,
    translatedDescription: PropTypes.string,
    weight: PropTypes.number.isRequired,
    potentialClassification: PropTypes.string.isRequired
  }).isRequired
};
