import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

export default function DataTableEditableColumn({ field, initialValue = '', onChangeCompleted }) {
  const [value, setValue] = useState(initialValue || '');

  const debounced = useRef(debounce((newValue) => onChangeCompleted(newValue, field.key), 1000));

  const onChange = (event) => {
    setValue(event.target.value);
    debounced.current(event.target.value);
  };

  return (
    <div
      data-header={field.name}
      className={`col-lg-${field.distribution}`}
    >
      <input
        type="text"
        value={value}
        className="form-control"
        onChange={onChange}
      />
    </div>
  );
}

DataTableEditableColumn.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distribution: PropTypes.number.isRequired,
    isTruncate: PropTypes.bool.isRequired,
    isSortable: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
  }).isRequired,
  initialValue: PropTypes.string,
  onChangeCompleted: PropTypes.func.isRequired
};
