import { useState, useMemo } from 'react';

import camelToSnake from '../../../utils/camelToSnake';

export default function useUrl(
  initialUrl,
  limit,
  offset,
  queryParams,
  isDisabledPagination = false
) {
  const [ordering, setOrdering] = useState([]);

  const orderingQueryParam = useMemo(() => (
    ordering.reduce((memo, item) => {
      if (memo !== '') {
        return `${memo},${item.direction === 'desc' ? '-' : ''}${camelToSnake(item.key)}`;
      }

      return `${item.direction === 'desc' ? '-' : ''}${camelToSnake(item.key)}`;
    }, '')
  ), [ordering]);

  const url = useMemo(() => {
    const urlToChange = new URL(initialUrl);
    if (!isDisabledPagination) {
      urlToChange.searchParams.append('limit', limit.toString());
      urlToChange.searchParams.append('offset', offset.toString());
    }

    if (orderingQueryParam !== '') {
      urlToChange.searchParams.append('ordering', orderingQueryParam);
    }

    queryParams.forEach((filter) => {
      if (filter.value && queryParams.value !== '') {
        urlToChange.searchParams.append(filter.name, filter.value);
      }
    });

    return urlToChange.toString(undefined);
  }, [initialUrl, limit, offset, orderingQueryParam, queryParams, isDisabledPagination]);

  const sort = useMemo(() => (
    ordering.reduce((memo, config, index) => ({
      ...memo,
      [config.key]: {
        direction: config.direction,
        position: index + 1
      }
    }), {})
  ), [ordering]);

  const sortBy = (key) => {
    setOrdering((prevOrdering) => {
      const index = prevOrdering.findIndex((item) => item.key === key);
      if (index < 0) {
        return [
          ...prevOrdering,
          {
            key,
            direction: 'asc'
          }
        ];
      }

      const config = prevOrdering[index];
      if (config.direction === 'asc') {
        return [
          ...prevOrdering.slice(0, index),
          { key, direction: 'desc' },
          ...prevOrdering.slice(index + 1)
        ];
      }

      return [
        ...prevOrdering.slice(0, index),
        ...prevOrdering.slice(index + 1)
      ];
    });
  };

  return {
    url,
    sort,
    sortBy
  };
}
