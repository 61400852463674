import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import ManifestGuide, {
  HEADER_CONTENT,
  HEADER_GUIDE,
  HEADER_STATUS,
  HEADER_WEIGHT
} from './ManifestGuide';
import useSize from '../hooks/useSize';
import useOnDesktop from '../hooks/useOnDesktop';


export default function ManifestGuides({ guides, listRef }) {
  const { size, elementRefToGetSize } = useSize();
  const onDesktop = useOnDesktop();

  function rowRenderer(
    {
      key,
      index,
      style
    }
  ) {
    return (
      <div
        className="container"
        style={style}
        key={key}
      >
        <ManifestGuide
          guide={guides[index]}
        />
      </div>
    );
  }

  return (
    <div className="manifest-guides m-0 px-0 grid h-100 d-flex flex-column">
      <div className="row no-gutters grid-header">
        <div className="col-5">{HEADER_GUIDE}</div>
        <div className="col-1 text-center">{HEADER_STATUS}</div>
        <div className="col-5">{HEADER_CONTENT}</div>
        <div className="col-1 text-center">{HEADER_WEIGHT}</div>
      </div>
      <div ref={elementRefToGetSize} className="manifest-guides-view-port h-100 w-100">
        {(size.width > 0 && size.height > 0) && (
          <List
            ref={listRef}
            width={size.width}
            height={size.height}
            rowCount={guides.length}
            rowHeight={onDesktop ? 100 : 380}
            rowRenderer={rowRenderer}
          />
        )}
      </div>
    </div>
  );
}

ManifestGuides.propTypes = {
  guides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      originGuideNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      originalCustomsStatus: PropTypes.string.isRequired,
      packagesAmount: PropTypes.number.isRequired,
      scannedPackagesAmount: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
      potentialClassification: PropTypes.string.isRequired,
      content: PropTypes.string
    })
  ).isRequired,
  listRef: PropTypes.shape({ current: PropTypes.instanceOf(List) })
};
