import { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce';

export default function useSize() {
  const elementRefToGetSize = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // ¯\_(ツ)_/¯ https://github.com/facebook/react/issues/13108
    if (elementRefToGetSize.current) {
      const readyStateChangeListener = () => {
        if (document.readyState === 'complete') {
          setSize({
            width: elementRefToGetSize.current.offsetWidth,
            height: elementRefToGetSize.current.offsetHeight
          });
        }
      };

      document.addEventListener('readystatechange', readyStateChangeListener);

      return () => {
        document.removeEventListener('readystatechange', readyStateChangeListener);
      };
    }
  }, []);

  useEffect(() => {
    const resizeListener = debounce(() => {
      setSize({
        width: elementRefToGetSize.current.offsetWidth,
        height: elementRefToGetSize.current.offsetHeight
      });
    }, 100);

    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    elementRefToGetSize,
    size
  };
}
